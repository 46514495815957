import React, { useState } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
  SubMenu,
} from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSignOutAlt,
  faChartBar,
  faHandHoldingUsd,
  faTachometerAlt,
  faEnvelope,
  faCogs,
  faChevronLeft,
  faChevronRight,
  faBuilding,
  faIdCard,
  faDollarSign,
  faHome,
  faVideo,
  faCamera,
  faMoneyCheck,
  faUserTie,
  faUserSecret,
  faCog,
  faPiggyBank,
  faEye,
  faFileAlt,
  faBell,
  faMoneyBillWave,
  faKey,
  faUsers,
  faTools,
  faTrash,
  faClipboardList,
  faFileInvoiceDollar,
  faWater,
  faPlusCircle,
  faSearch,
  faEdit,
  faTrashAlt,
  faBalanceScale,
  faCoins,
  faPercentage,
  faArrowRightToBracket,
  faArrowRightFromBracket,
  faUniversity,
  faWallet,
  faLink,
  faSyncAlt,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";
const Home = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false); // Manage sidebar state
  const handleProfileClick = () => {
    // Redirect to profile screen
    navigate("/admin/profile");
  };

  const handleLogoutClick = () => {
    // Redirect to logout screen
    navigate("/logout");
  };

  const handleUserBalancesClick = () => {
    navigate("/admin/userbalances");
  };

  const handleOwnerBalancesClick = () => {
    navigate("/admin/ownerbalances");
  };

  const handleReservationsClick = () => {
    navigate("/admin/reservations");
  };

  const handleBillClick = () => {
    navigate("/admin/bill");
  };

  const handleWaterBillClick = () => {
    navigate("/admin/waterbill");
  };

  const handleVacatingClick = () => {
    navigate("/admin/vacating");
  };

  const handleVideosClick = () => {
    navigate("/admin/videos");
  };

  const handlePhotosClick = () => {
    navigate("/admin/photos");
  };

  const handlePromocodesClick = () => {
    navigate("/admin/promocodes");
  };

  const handleAllUsersClick = () => {
    navigate("/admin/users");
  };

  const handleBank = () => {
    navigate("/admin/bank");
  };

  const handlePropertiesClick = () => {
    navigate("/admin/propertypage");
  };

  const handleRentalsClick = () => {
    navigate("/admin/rentals");
  };

  const handleMoneyOutClick = () => {
    navigate("/admin/moneyout");
  };

  const handleMoneyInClick = () => {
    navigate("/admin/moneyin");
  };

  const handleDepositClick = () => {
    navigate("/admin/deposit");
  };

  const handleMonthlyFeesClick = () => {
    navigate("/admin/monthlyfees");
  };

  const handlePropertyKYCClick = () => {
    navigate("/admin/propertykyc");
  };

  const handlePropertyHunter = () => {
    navigate("/admin/propertyhunter");
  };

  const handleViewSettlementUser = () => {
    navigate("/admin/viewusersettlement");
  };

  const handleViewSettlementLandlord = () => {
    navigate("/admin/viewlandlordsettlement");
  };

  const handleViewSettlementHunter = () => {
    navigate("/admin/viewpropertyhuntersettlement");
  };
  const handleViewDepositSettlements = () => {
    navigate("/admin/admincompletesettlements");
  };

  const handleViewNotifications = () => {
    navigate("/admin/viewnotifications");
  };
  const handleRecordsView = () => {
    navigate("/admin/recordsview");
  };
  const handleViewAuditLogs = () => {
    navigate("/admin/auditlogs");
  };
  const handleAgencyViewingFee = () => {
    navigate("/admin/agencyviewing");
  };
  const handleMaintenaceRequests = () => {
    navigate("/admin/maintenancerequest");
  };
  const handleWithdrawals = () => {
    navigate("/admin/withdrawals");
  };
  const handleVerifyCodes = () => {
    navigate("/admin/viewverifycodes");
  };
  const handleAdminSettlementsView = () => {
    navigate("/admin/viewadminsettlements");
  };
  const handleServiceCharge = () => {
    navigate("/admin/serviceratecharge");
  };
  const handleWithdrawalLink = () => {
    navigate("/admin/withdrawalshelplandlord");
  };

  const handleAddReservationBills = () => {
    navigate("/admin/addreservationbills");
  };

  const handleCheckWaterUnits = () => {
    navigate("/admin/checkwaterunits");
  };

  const handleUpdateLandlordBalances = () => {
    navigate("/admin/updatelandlordbalance");
  };
  const handleUpdateUserBalances = () => {
    navigate("/admin/updateuserbalances");
  };

  const handleUpdateBills = () => {
    navigate("/admin/updatebills");
  };

  const handleEmailSending = () => {
    navigate("/admin/emailsender");
  };

  const handlePropertyTransactions = () => {
    navigate("/admin/transactionsviewpage");
  };

  const handleTrashUnitsPrice = () => {
    navigate("/admin/checktrashprice");
  };

  const handleOfflinePayments = () => {
    navigate("/admin/viewofflinepayments");
  };

  const handleAllTrashUnitsPrice = () => {
    navigate("/admin/alltrashunitsprice");
  };

  const handleViewTrashBills = () => {
    navigate("/admin/viewtrashbills");
  };
  const handleManualReservationsClick = () => {
    navigate("/admin/manualreservations");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <div className="flex ">
          {/* Sidebar */}
          <ProSidebar collapsed={collapsed}>
            {/* Sidebar Header */}
            <SidebarHeader>
              <div className="flex justify-between items-center p-4">
                <h2
                  className={`text-xl font-semibold text-white ${
                    collapsed ? "hidden" : "block"
                  }`}
                >
                  Admin Dashboard
                </h2>
                <button
                  onClick={() => setCollapsed(!collapsed)}
                  className="text-white bg-transparent border-none cursor-pointer p-2"
                >
                  {collapsed ? (
                    <FontAwesomeIcon icon={faChevronRight} size="lg" />
                  ) : (
                    <FontAwesomeIcon icon={faChevronLeft} size="lg" />
                  )}
                </button>
              </div>
            </SidebarHeader>

            {/* Sidebar Content */}
            <SidebarContent>
              <Menu iconShape="circle">
                <MenuItem
                  icon={<FontAwesomeIcon icon={faUser} />}
                  onClick={handleProfileClick}
                >
                  Profile
                </MenuItem>

                <SubMenu
                  title="Finances"
                  icon={<FontAwesomeIcon icon={faChartBar} />}
                >
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faBalanceScale} />}
                    onClick={handleUserBalancesClick}
                  >
                    User Balances
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faCoins} />}
                    onClick={handleOwnerBalancesClick}
                  >
                    Owner Balances
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faPercentage} />}
                    onClick={handlePromocodesClick}
                  >
                    Promocodes
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
                    onClick={handleMoneyOutClick}
                  >
                    MoneyOut
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faArrowRightToBracket} />}
                    onClick={handleMoneyInClick}
                  >
                    MoneyIn
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    onClick={handleMonthlyFeesClick}
                  >
                    MonthlyFees
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faUniversity} />}
                    onClick={handleBank}
                  >
                    Bank
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faWallet} />}
                    onClick={handleWithdrawals}
                  >
                    Withdrawals
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faLink} />}
                    onClick={handleWithdrawalLink}
                  >
                    Withdrawal Link Landlord
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faSyncAlt} />}
                    onClick={handleUpdateLandlordBalances}
                  >
                    UpdateLandlordBalances
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faSyncAlt} />}
                    onClick={handleUpdateUserBalances}
                  >
                    UpdateUserBalances
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faReceipt} />}
                    onClick={handleOfflinePayments}
                  >
                    OfflinePayments
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faHome} />}
                    onClick={handlePropertyTransactions}
                  >
                    PropertyTransactions
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faUserTie} />}
                    onClick={handlePropertyHunter}
                  >
                    PropertyHunter
                  </MenuItem>
                </SubMenu>

                <SubMenu
                  title="Management"
                  icon={<FontAwesomeIcon icon={faClipboardList} />}
                >
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faClipboardList} />}
                    onClick={handleReservationsClick}
                  >
                    Reservations
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faClipboardList} />}
                    onClick={handleManualReservationsClick}
                  >
                    Manual Reservations
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                    onClick={handleBillClick}
                  >
                    Bill
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faWater} />}
                    onClick={handleWaterBillClick}
                  >
                    WaterBill
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faPlusCircle} />}
                    onClick={handleAddReservationBills}
                  >
                    AddBills
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faSearch} />}
                    onClick={handleCheckWaterUnits}
                  >
                    CheckWaterUnits
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faEdit} />}
                    onClick={handleUpdateBills}
                  >
                    UpdateBills
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                    onClick={handleTrashUnitsPrice}
                  >
                    TrashUnitsPrice
                  </MenuItem>
                </SubMenu>
                <SubMenu title="Views" icon={<FontAwesomeIcon icon={faEye} />}>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faFileAlt} />}
                    onClick={handleViewAuditLogs}
                  >
                    ViewAuditLogs
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faFileAlt} />}
                    onClick={handleRecordsView}
                  >
                    View Records
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faBell} />}
                    onClick={handleViewNotifications}
                  >
                    ViewNotifications
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faMoneyBillWave} />}
                    onClick={handleAgencyViewingFee}
                  >
                    ViewAgencyViewingFee
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faPiggyBank} />}
                    onClick={handleViewDepositSettlements}
                  >
                    ViewDepositSettlements
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faKey} />}
                    onClick={handleVerifyCodes}
                  >
                    VerifyCodes
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faUsers} />}
                    onClick={handleAllUsersClick}
                  >
                    ViewUsers
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faTools} />}
                    onClick={handleMaintenaceRequests}
                  >
                    MaintenaceRequests
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={handleViewTrashBills}
                  >
                    ViewTrashBills
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    onClick={handleAllTrashUnitsPrice}
                  >
                    AllTrashUnitsPrice
                  </MenuItem>
                </SubMenu>
                <SubMenu
                  title="Settlements"
                  icon={<FontAwesomeIcon icon={faMoneyCheck} />}
                >
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faUser} />}
                    onClick={handleViewSettlementUser}
                  >
                    ViewSettlementUser
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faUserTie} />}
                    onClick={handleViewSettlementLandlord}
                  >
                    ViewSettlementLandlord
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faUserSecret} />}
                    onClick={handleViewSettlementHunter}
                  >
                    ViewSettlementHunter
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faCog} />}
                    onClick={handleAdminSettlementsView}
                  >
                    AdminSettlementsView
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faSignOutAlt} />}
                    onClick={handleVacatingClick}
                  >
                    Vacating
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faPiggyBank} />}
                    onClick={handleDepositClick}
                  >
                    Deposit
                  </MenuItem>
                </SubMenu>
                <SubMenu
                  title="Properties"
                  icon={<FontAwesomeIcon icon={faBuilding} />}
                >
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faBuilding} />}
                    onClick={handlePropertiesClick}
                  >
                    Properties
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faIdCard} />}
                    onClick={handlePropertyKYCClick}
                  >
                    PropertyKYC
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faDollarSign} />}
                    onClick={handleServiceCharge}
                  >
                    ServiceCharge
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faHome} />}
                    onClick={handleRentalsClick}
                  >
                    Rentals
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faVideo} />}
                    onClick={handleVideosClick}
                  >
                    Videos
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faCamera} />}
                    onClick={handlePhotosClick}
                  >
                    Photos
                  </MenuItem>
                </SubMenu>
                <SubMenu
                  title="Communication"
                  icon={<FontAwesomeIcon icon={faEnvelope} />}
                >
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faEnvelope} />}
                    onClick={handleEmailSending}
                  >
                    Send Email
                  </MenuItem>
                </SubMenu>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faSignOutAlt} />}
                  onClick={handleLogoutClick}
                >
                  Logout
                </MenuItem>
              </Menu>
            </SidebarContent>

            {/* Sidebar Footer */}
            <SidebarFooter>
              <div className="text-white text-center p-4">
                <p className="text-sm">© 2024 Reivax Softwares</p>
                <p className="text-sm">All Rights Reserved</p>
              </div>
            </SidebarFooter>
          </ProSidebar>
        </div>
      </div>
    </AuthGuard>
  );
};

const Button = ({ children, onClick }) => (
  <button
    onClick={onClick}
    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
  >
    {children}
  </button>
);

export default Home;
