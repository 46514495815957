import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom"; // Import Navigate for redirection
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify"; // Import ToastContainer
import "./style.css"; // Import Tailwind CSS
import Login from "./Auth/Login";
import OtpVerification from "./Auth/OtpVerification";
import Registration from "./Auth/Registration";
import ForgotPassword from "./Auth/ForgotPassword";
import Logout from "./Auth/Logout";
import Home from "./admin/home";
import Profile from "./admin/profile";
import PropertyPage from "./admin/propertypage";
import UpdateProperty from "./admin/updateproperty";
import ReservationsPage from "./admin/reservations";
import Rentals from "./admin/rentals";
import UpdateRental from "./admin/updaterental";
import SpecificRental from "./admin/specificrental";
import PropertyKYCPage from "./admin/propertykyc";
import BillsPage from "./admin/bills";
import { useEffect } from "react";
import Photo from "./admin/photo";
import Video from "./admin/video";
import WaterBillPage from "./admin/waterbill";
import ViewVacatePage from "./admin/vacating";
import UserBalancePage from "./admin/userbalance";
import OwnerBalancePage from "./admin/ownerbalances";
import PromoCodesPage from "./admin/promocode";
import Bank from "./admin/bank";
import UsersPage from "./admin/users";
import MoneyOutPage from "./admin/moneyout";
import MoneyInPage from "./admin/moneyin";
import DepositPage from "./admin/deposit";
import MonthlyFeesPage from "./admin/monthlyfees";
import PropertyHunter from "./admin/propertyhunter";
import WithdrawalsPage from "./admin/settlehousehunters";
import UserSettlements from "./admin/viewusersettlement";
import LandlordSettlements from "./admin/viewlandlordsettlement";
import HouseHunterSettlements from "./admin/viewhousehuntersettlement";
import VacatingSttlement from "./admin/vacatingsettlements";
import NotificationsPage from "./admin/notifications";
import RecordsPage from "./admin/recordsview";
import AuditLog from "./admin/auditlogs";
import AgencyViewingFee from "./admin/agencyviewing";
import MaintenanceRequest from "./admin/maintenancerequest";
import WithdrawalsAdminPage from "./admin/withdrawals";
import VerifyCodes from "./admin/viewverifycodes";
import AdminSettlements from "./admin/viewadminsettlements";
import ServiceChargePage from "./admin/serviceratecharge";
import WithdrawalsLandlordAdminPage from "./admin/withdrawalshelplandlord";
import BillsPropertyPage from "./admin/addpropertybills";
import AddReservationsBillsPage from "./admin/addreservationbills";
import WaterUnitsPage from "./admin/checkwaterunits";
import UpdateWaterUnitsPage from "./admin/waterunitsupdate";
import UpdateLandlordBalance from "./admin/updatelandlordbalance";
import UpdateUserBalance from "./admin/updateuserbalances";
import UpdateBills from "./admin/updatebills";
import EmailSender from "./admin/emailsender";
import TransactionsViewPage from "./admin/transactionsviewpage";
import TrashUnitsPage from "./admin/checktrashprice";
import OfflinePayments from "./admin/viewofflinepayments";
import UpdateTrashUnitsPage from "./admin/trashunitsupdate";
import AllTrashFeesPage from "./admin/alltrashunitsprice";
import ViewTrashBillsPage from "./admin/viewtrashbills";
import ManualReservations from "./admin/manualreservations";

function App() {
  return (
    <Router>
      <Routes>
        {/* Routes accessible whether user is logged in or not */}
        <Route path="/" element={<Login />} />
        <Route path="/OtpVerification" element={<OtpVerification />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/logout" element={<Logout />} />

        {/* Routes accessible only when user is logged in */}

        <Route path="/admin/home" element={<Home />} />
        <Route path="/admin/profile" element={<Profile />} />
        <Route path="/admin/propertypage" element={<PropertyPage />} />
        <Route path="/admin/updateproperty" element={<UpdateProperty />} />
        <Route path="/admin/reservations" element={<ReservationsPage />} />
        <Route path="/admin/propertykyc" element={<PropertyKYCPage />} />
        <Route path="/admin/rentals" element={<Rentals />} />
        <Route path="/admin/updaterental" element={<UpdateRental />} />
        <Route path="/admin/specificrental" element={<SpecificRental />} />
        <Route path="/admin/bill" element={<BillsPage />} />
        <Route path="/admin/photos" element={<Photo />} />
        <Route path="/admin/videos" element={<Video />} />
        <Route path="/admin/waterbill" element={<WaterBillPage />} />
        <Route path="/admin/vacating" element={<ViewVacatePage />} />
        <Route path="/admin/userbalances" element={<UserBalancePage />} />
        <Route path="/admin/ownerbalances" element={<OwnerBalancePage />} />
        <Route path="/admin/promocodes" element={<PromoCodesPage />} />
        <Route path="/admin/bank" element={<Bank />} />
        <Route path="/admin/users" element={<UsersPage />} />
        <Route path="/admin/moneyout" element={<MoneyOutPage />} />
        <Route path="/admin/moneyin" element={<MoneyInPage />} />
        <Route path="/admin/deposit" element={<DepositPage />} />
        <Route path="/admin/monthlyfees" element={<MonthlyFeesPage />} />
        <Route path="/admin/propertyhunter" element={<PropertyHunter />} />
        <Route path="/admin/settlepage" element={<WithdrawalsPage />} />
        <Route
          path="/admin/admincompletesettlements"
          element={<VacatingSttlement />}
        />
        <Route
          path="/admin/viewpropertyhuntersettlement"
          element={<HouseHunterSettlements />}
        />
        <Route
          path="/admin/viewlandlordsettlement"
          element={<LandlordSettlements />}
        />
        <Route path="/admin/viewusersettlement" element={<UserSettlements />} />
        <Route
          path="/admin/viewnotifications"
          element={<NotificationsPage />}
        />
        <Route path="/admin/recordsview" element={<RecordsPage />} />
        <Route path="/admin/auditlogs" element={<AuditLog />} />
        <Route path="/admin/agencyviewing" element={<AgencyViewingFee />} />
        <Route
          path="/admin/maintenancerequest"
          element={<MaintenanceRequest />}
        />
        <Route path="/admin/withdrawals" element={<WithdrawalsAdminPage />} />
        <Route path="/admin/viewverifycodes" element={<VerifyCodes />} />
        <Route
          path="/admin/viewadminsettlements"
          element={<AdminSettlements />}
        />
        <Route
          path="/admin/serviceratecharge"
          element={<ServiceChargePage />}
        />
        <Route
          path="/admin/withdrawalshelplandlord"
          element={<WithdrawalsLandlordAdminPage />}
        />
        <Route path="/admin/addpropertybills" element={<BillsPropertyPage />} />
        <Route path="/admin/checkwaterunits" element={<WaterUnitsPage />} />
        <Route
          path="/admin/updatelandlordbalance"
          element={<UpdateLandlordBalance />}
        />
        <Route
          path="/admin/waterunitsupdate"
          element={<UpdateWaterUnitsPage />}
        />
        <Route
          path="/admin/addreservationbills"
          element={<AddReservationsBillsPage />}
        />
        <Route
          path="/admin/updateuserbalances"
          element={<UpdateUserBalance />}
        />
        <Route path="/admin/updatebills" element={<UpdateBills />} />
        <Route path="/admin/checktrashprice" element={<TrashUnitsPage />} />
        <Route
          path="/admin/viewofflinepayments"
          element={<OfflinePayments />}
        />
        <Route path="/admin/emailsender" element={<EmailSender />} />
        <Route
          path="/admin/transactionsviewpage"
          element={<TransactionsViewPage />}
        />
        <Route
          path="/admin/trashunitsupdate"
          element={<UpdateTrashUnitsPage />}
        />
        <Route
          path="/admin/alltrashunitsprice"
          element={<AllTrashFeesPage />}
        />
        <Route path="/admin/viewtrashbills" element={<ViewTrashBillsPage />} />
        <Route
          path="/admin/manualreservations"
          element={<ManualReservations />}
        />

        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;

export function AuthGuard({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    // Retrieve the JSON string from localStorage
    const userDataString = localStorage.getItem("user");

    try {
      // Parse the JSON string to convert it into a JavaScript object
      const userData = JSON.parse(userDataString);
      // Access the 'unique' property from the 'user' object
      const userId = userData.unique;
      if (!userId) {
        navigate("/");
      }
    } catch (error) {
      navigate("/");
    }
  }, []);

  return <>{children}</>;
}

export function TokenGuard({ children }) {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  return <>{children}</>;
}
