import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { Oval } from "react-loader-spinner"; // Import loader

const TrashUnitsPage = () => {
  const [trashFee, setTrashFee] = useState({
    amount: null,
  }); // State for trash fee as an object
  const [showAddModal, setShowAddModal] = useState(false); // State for showing add modal
  const [amount, setAmount] = useState(null); // State for amount in modal
  const [propertyId, setPropertyId] = useState(""); // State for propertyId input
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (propertyId) {
      setIsLoading(true); // Start loading before making the API call
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/trashfee/fetchonetrashfee/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.response) {
            setTrashFee(response.data.response); // Set trash fee data if available
            toast.success("Trash fee data fetched successfully!");
          } else {
            setTrashFee({}); // If no data, clear the trashFee state
          }
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error(
            error.response?.data?.response || "Failed to fetch trash fee data."
          );
          console.error("Error fetching trash fee:", error);
          setIsLoading(false); // Stop loading on error
        });
    }
  }, [propertyId]); // Trigger the effect whenever propertyId changes

  const handleAddTrashFee = () => {
    setIsLoading(true); // Set loading state to true
    // Create FormData object
    const formData = new FormData();
    formData.append("feeamount", amount);

    const token = localStorage.getItem("token");

    // Send POST request to add trash fee
    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/trashfee/posttrashfee/${propertyId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
        setIsLoading(false); // Set loading state to false
        setShowAddModal(false); // Close the modal after adding trash fee
      })
      .catch((error) => {
        toast.error(error.response.data.response);
        console.error("Error adding trash fee:", error);
        setIsLoading(false); // Reset loading state in case of error
      });
  };

  const handleUpdateTrashFee = () => {
    localStorage.setItem("propertyIdTrash", JSON.stringify(propertyId));
    navigate("/admin/trashunitsupdate"); // Navigate to trash fee update page
  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Trash Fee</h1>

        {/* Property ID Input */}
        <div className="mb-4">
          <label htmlFor="propertyId" className="block text-sm font-semibold">
            Enter Property ID:
          </label>
          <input
            type="text"
            id="propertyId"
            className="w-full border rounded-md py-2 px-3"
            value={propertyId}
            onChange={(e) => setPropertyId(e.target.value)} // Update the propertyId state
            placeholder="Enter property ID"
          />
        </div>

        {/* Trash Fee Details */}
        <div>
          <p>
            If you already have a trash fee set, please update it. Do not add a
            duplicate entry.
          </p>

          {/* Display current Trash Fee if available */}
          {trashFee?.feeamount !== undefined ? (
            <>
              <p>Current Trash Fee Amount: {trashFee.feeamount}</p>
              {/* Update Trash Fee Button */}
              <button
                onClick={handleUpdateTrashFee}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 ml-2"
                disabled={!propertyId || isLoading} // Disable if no propertyId or loading
              >
                Update Trash Fee
              </button>
            </>
          ) : (
            <p>No trash fee data available.</p> // If no trash fee data, show message
          )}

          {/* Add Trash Fee Button (only visible when no data exists) */}
          {trashFee?.feeamount === undefined && !isLoading && (
            <button
              onClick={() => setShowAddModal(true)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
              disabled={!propertyId || isLoading} // Disable if no propertyId or loading
            >
              Add Trash Fee
            </button>
          )}

          {/* Back Button */}
          <button
            onClick={handleBack}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mt-4 ml-2"
          >
            Back
          </button>
        </div>

        {/* Modal for Adding Trash Fee */}
        {showAddModal && (
          <Modal
            isOpen={showAddModal}
            onRequestClose={() => setShowAddModal(false)}
            className="modal"
          >
            <div className="bg-white w-1/3 p-6 rounded-lg">
              <h2 className="text-2xl font-semibold mb-4">Add Trash Fee</h2>
              <p>
                If you already have a trash fee set, please update it. Do not
                add a duplicate entry.
              </p>
              <div className="mb-4">
                <label htmlFor="amount" className="block text-sm font-semibold">
                  Amount:
                </label>
                <input
                  type="number"
                  id="amount"
                  className="w-full border rounded-md py-2 px-3"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter amount"
                />
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={handleAddTrashFee}
                  disabled={!amount || isLoading} // Disable if no amount or loading
                  className={`${
                    isLoading
                      ? "bg-gray-500"
                      : "bg-indigo-600 hover:bg-indigo-700"
                  } text-white font-bold py-2 px-4 rounded`}
                >
                  {isLoading ? (
                    <Oval
                      height={20}
                      width={20}
                      color="white"
                      visible={true}
                      ariaLabel="loading-indicator"
                      secondaryColor="white"
                    />
                  ) : (
                    "Add"
                  )}
                </button>
                <button
                  onClick={() => setShowAddModal(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </AuthGuard>
  );
};

export default TrashUnitsPage;
