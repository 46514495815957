import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { Oval } from "react-loader-spinner"; // Import loader

const ViewTrashBillsPage = () => {
  const [trashBills, setTrashBills] = useState([]); // State to store fetched trash fees
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading before making the API call
    axios
      .get(
        "https://backend.inyumba.co.ke/iNyumba/admin/trashbill/fetchalltrashbill",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.response) {
          setTrashBills(response.data.response); // Set trash fees data if available
          toast.success("Trash fees data fetched successfully!");
        } else {
          setTrashBills([]); // Clear the trash fees state if no data
        }
        setIsLoading(false); // Stop loading
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.response || "Failed to fetch trash fees."
        );
        console.error("Error fetching trash fees:", error);
        setIsLoading(false); // Stop loading on error
      });
  }, []); // Fetch trash fees once on component mount

  const handleDeleteTrashFee = (propertyId) => {
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading before making the delete request
    axios
      .delete(
        `https://backend.inyumba.co.ke/iNyumba/trashfee/deletetrashfee/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response); // Show success message
        setTrashBills(
          trashBills.filter((fee) => fee.propertyid !== propertyId)
        ); // Remove deleted trash fee from the list
        setIsLoading(false); // Stop loading
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.response || "Failed to delete trash fee."
        );
        console.error("Error deleting trash fee:", error);
        setIsLoading(false); // Stop loading on error
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Trash Bills</h1>

        {/* Loading Spinner */}
        {isLoading && (
          <div className="flex justify-center items-center mb-4">
            <Oval height={40} width={40} color="#4e73df" visible={true} />
          </div>
        )}

        {/* Trash Fees Table */}
        {!isLoading && trashBills.length > 0 && (
          <table className="table-auto w-full border-collapse border border-gray-200">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b">TrashBill ID</th>
                <th className="px-4 py-2 border-b">Property ID</th>
                <th className="px-4 py-2 border-b">Rental ID</th>
                <th className="px-4 py-2 border-b">House Number</th>
                <th className="px-4 py-2 border-b">User ID</th>
                <th className="px-4 py-2 border-b">Amount</th>
                <th className="px-4 py-2 border-b">Created at</th>
                <th className="px-4 py-2 border-b">Updated at</th>
              </tr>
            </thead>
            <tbody>
              {trashBills.map((trashBill) => (
                <tr key={trashBill.propertyid}>
                  <td className="px-4 py-2 border-b">
                    {trashBill.trashbillid}
                  </td>
                  <td className="px-4 py-2 border-b">{trashBill.propertyid}</td>
                  <td className="px-4 py-2 border-b">{trashBill.rentalid}</td>
                  <td className="px-4 py-2 border-b">
                    {trashBill.housenumber}
                  </td>
                  <td className="px-4 py-2 border-b">{trashBill.userid}</td>
                  <td className="px-4 py-2 border-b">{trashBill.amount}</td>
                  <td className="px-4 py-2 border-b">{trashBill.created_at}</td>
                  <td className="px-4 py-2 border-b">{trashBill.updated_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* If no trash fees are found */}
        {!isLoading && trashBills.length === 0 && (
          <p>No trash bills available.</p>
        )}
      </div>
    </AuthGuard>
  );
};

export default ViewTrashBillsPage;
