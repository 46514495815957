import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const UpdateBills = () => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editBillId, setEditBillId] = useState(null);
  const [serviceFee, setServiceFee] = useState("");
  const [totalAmount, setTotalAmount] = useState("");

  useEffect(() => {
    fetchUserBills();
  }, []);

  const fetchUserBills = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://backend.inyumba.co.ke/iNyumba/admin/bill/viewallbills",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBills(response.data.response);
    } catch (error) {
      toast.error("Failed to fetch bills");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (bill) => {
    setEditBillId(bill.bill_id); // Store the bill ID for editing
    setServiceFee(bill.service_fee); // Set current service fee
    setTotalAmount(bill.total_amount); // Set current total amount
  };

  const handleUpdate = async () => {
    if (!editBillId) {
      toast.error("No bill selected for update");
      return;
    }

    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        `https://backend.inyumba.co.ke/iNyumba/admin/bill/updatebill/${editBillId}`,
        {
          servicefee: serviceFee,
          totalamount: totalAmount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.data.response);
      setEditBillId(null); // Hide the edit section
      fetchUserBills(); // Refresh the bill list
    } catch (error) {
      toast.error(error.response.data.response);
      setEditBillId(null); // Hide the edit section after failure
    }
  };

  if (loading) {
    return <div className="text-center mt-6">Loading...</div>;
  }

  // Filter bills to only show those with payment_status 'PENDING'
  const pendingBills = bills.filter(
    (bill) => bill.payment_status === "PENDING"
  );

  const handleDelete = async (billId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `https://backend.inyumba.co.ke/iNyumba/admin/bill/deleteonebill/${billId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Bill deleted successfully");
      fetchUserBills(); // Refresh the bill list after deletion
    } catch (error) {
      toast.error("Failed to delete bill");
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-center mb-6">Update Bills</h1>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2 px-4 border-b">Bill ID</th>
            <th className="py-2 px-4 border-b">Rental ID</th>
            <th className="py-2 px-4 border-b">Property ID</th>
            <th className="py-2 px-4 border-b">User ID</th>
            <th className="py-2 px-4 border-b">Water Bill</th>
            <th className="py-2 px-4 border-b">Rent Bill</th>
            <th className="py-2 px-4 border-b">Service Fee</th>
            <th className="py-2 px-4 border-b">Trash Fee</th>
            <th className="py-2 px-4 border-b">Total Amount</th>
            <th className="py-2 px-4 border-b">Status</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {pendingBills.length > 0 ? (
            pendingBills.map((bill) => (
              <React.Fragment key={bill.bill_id}>
                <tr className="border-b hover:bg-gray-100">
                  <td className="py-2 px-4">{bill.bill_id}</td>
                  <td className="py-2 px-4">{bill.rental_id}</td>
                  <td className="py-2 px-4">{bill.property_id}</td>
                  <td className="py-2 px-4">{bill.user_id}</td>
                  <td className="py-2 px-4">{bill.water_bill}</td>
                  <td className="py-2 px-4">{bill.rent_bill}</td>
                  <td className="py-2 px-4">{bill.service_fee}</td>
                  <td className="py-2 px-4">{bill.trashbill}</td>
                  <td className="py-2 px-4">{bill.total_amount}</td>
                  <td className="py-2 px-4">{bill.payment_status}</td>
                  <td className="py-2 px-4">
                    <button
                      onClick={() => handleEdit(bill)}
                      className="bg-blue-500 text-white py-1 px-2 rounded hover:bg-blue-600 mr-2"
                    >
                      Edit
                    </button>

                    <button
                      onClick={() => handleDelete(bill.bill_id)}
                      className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
                {editBillId === bill.bill_id && (
                  <tr className="border-b bg-gray-50">
                    <td colSpan="10" className="py-4 px-4">
                      <div className="flex flex-col space-y-4">
                        <div>
                          <label className="block text-gray-700 mb-2">
                            Service Fee
                          </label>
                          <input
                            type="text"
                            value={serviceFee}
                            onChange={(e) => setServiceFee(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded"
                          />
                        </div>
                        <div>
                          <label className="block text-gray-700 mb-2">
                            Total Amount
                          </label>
                          <input
                            type="text"
                            value={totalAmount}
                            onChange={(e) => setTotalAmount(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded"
                          />
                        </div>
                        <button
                          onClick={handleUpdate}
                          className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
                        >
                          Update
                        </button>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center py-4">
                No pending bills found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UpdateBills;
